*{
    margin: 0;
    padding: 0; 
    font-family: 'ProximaNova-Regular', sans-serif;
}

body{
    background-color: #F2F2F2;
}


/*Header*/
li{
    list-style: none;
}
a{ 
    text-decoration: none;
    color: #000000;
    font-size: 1rem;
}
a:hover { 
    color: #0A73E5;
}

header {
    background-color: white ;
     position: relative;
     padding: 0 2rem;
     padding-top: 1rem; /* padding en la parte superior */
     padding-bottom: 1rem; /* padding en la parte inferior */
}

.navbar {
    width: 100%;
    height: 60px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar .logo a{
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar .links {
    display: flex;
    gap: 2rem;
}
.navbar .toggle_btn{
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
}

.action_btn {
    color: #0A73E5;
}
.action_btn:hover {
    color: #054c97;
}

.dropdown_menu {    
    display: none;
    position: absolute;
    border-radius: 15px;
    right: 2rem;
    top: 60px;
    height: 0;
    width: 300px;
    background: rgba(255, 255, 255, 0.432);
    backdrop-filter: blur(15px);
    overflow: hidden;
    transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open{
    height: 200px;
}

.dropdown_menu li { 
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 992px){
    .navbar .links, .navbar .action_btn{
        display: none;
      
    }
    .links {
        font-weight: 600;
    }

    .navbar .toggle_btn {
        display: grid;
        
    }

    .dropdown_menu {
        display: block;
    }
}

@media(max-width: 576px){
    .dropdown_menu {
        left: 2rem;
        width: unset;
    }
}


/* Main */

.form {
    height: 656px;
    background: url(../assets/images/front-bg-small.f14153.jpg) 0/cover;
    display: grid;
    place-content: center;
    min-height: 75vh;

}
.form  > div:first-child {
    color: white;
    font-weight: bold;
    font-size: 44px;
    width: 450px;
    margin-bottom: 20px;
}
.searchBar {
   display: grid;
   grid-template-columns: 370px 80px; 
}

.form__input {
    border-radius: 3px;
    border: 0px;
    height: 40px;
    font-size: 28px;
    font-weight: bold;
    padding: 10px;
}
.form__inputVS{
    border-radius: 3px;
    border: 0px;
    height: 40px;
    font-size: 28px;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 20px;
}
.form__submit {
    background-color: #0A73E5;
    border: none; /* Eliminar borde */
    outline: none; /* Eliminar contorno */
    box-shadow: none; /* Eliminar sombra */
    border-radius: 3px;
}
.form__submitvs{
    width: 110px;
    height: 61px;
    background-color: #0A73E5;
    border: none; /* Eliminar borde */
    outline: none; /* Eliminar contorno */
    box-shadow: none; /* Eliminar sombra */
    border-radius: 3px;
}
.searchbuttonvs{
    display: grid;
    place-content: center;
}

@media(max-width: 992px){
    .searchBar {
        grid-template-columns: 220px 80px;
    }
    .form  > div:first-child {
        font-size: 30px;
        width: 300px;
    }
}

/* Info Player */
.infoTable {
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: white;
    padding: 120px;
}
.iconImg {
    height: 117px;
}
.clubImg {
    height: 23px;
}
.details__name {
    font-size: 40px;
    font-weight: bold;
}
.details__tag {
    text-align: right;
    font-size: 20px;
    color: #3B4151;
    font-weight: 600;
}
.details__club {
    display: flex;
    align-items: center;
    color: #3B4151;
    font-size: 20px;
    font-weight: 600;
    gap: 5px;
}
.details__item {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    margin-bottom: 60px;
}
.filaInfo {
    margin-top: 10px;
    background-color: #F2F2F2;
   
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
}

.columnaInfo {
    display: grid;
    grid-template-columns: 10% 70% 20%;
}
.columnaInfo img {
    height: 35px;
}
.columnaInfo div {
    font-size: 18px;
    font-weight: 600;
    align-content:  center;
}
.filaInfo > div:first-child {
    margin-right: 20px;
}
.columnaInfo > div:nth-child(2) {
    text-align: left;
    
}
.columnaInfo > div:nth-child(3) {
    text-align: right;
    
}
@media(max-width: 850px){
    .infoTable {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
    }
    .filaInfo {
        grid-template-columns: 1fr;
    }
    .filaInfo > div:first-child {
        margin-right: 0;
    }
    .columnaInfo {
        display: grid;
        grid-template-columns: 15% 65% 20%;
    }
    .details__item {
        display: grid;
        grid-template-columns: 1fr 4fr;
        margin-bottom: 20px;
    }
    .details__item > div:nth-child(2) {
        margin-left: 20px;
        
    }
    .details__name {
        font-size: 35px;
    
    }
}
/* Battle Log */
.battleContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 60px);
    margin-top: 20px;
}
.gamePlayed {
    display: grid;
    place-content: center;
    width: 48px;
    height: 48px;
    
    border: 2px solid black;
    margin-bottom: 8px;
}
.gamePlayed img {
  
    height: 36px;
}
@media(max-width: 850px){
    .battleContainer {
        padding: 10px;
    }
    h3 {
        padding: 10px;
    }
}

/* Personal Brawlers */
.titleBrawlers {
    margin-top: 50px;
}
.details{
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.brawlersContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill,270px);
    grid-gap: 20px;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 20px;
}
.brawlContainer{
   border: #000000 2px solid;
}

.brawlContainer img {
    height: 112px;
}
.boxBrawl{
    height: 112px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.infoBrawl{
    height: 48px;
    color: white;
    background-color: #000000;
    display: grid;
    grid-template-columns: 35% 19% 23% 23.7%;

}
.infoBrawl div{
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
}
.skillsBrawl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
    padding: 7px;
}
.skillsBrawl div img {
    height: 28px;
}
.skillsBrawl div {
    margin-top: 5px;
    display: grid;
    justify-items: center;
}
.nameBrawl {
 font-weight: 600;
 font-size: 14px;
}
@media(max-width: 850px){
    .brawlersContainer { 
        justify-content: center;
        
    }
    .titleBrawlers{
        padding-left: 10px;
    }
    
}
/* Footer */
.logoFooter {
   
    width: 150px;
    background: url("../assets/images/bs-logo.png") 0/cover;
}
.containerFooter > div:last-child {
    display: grid;
    justify-content: right;
}
.infoFooter{
    color: white;
}

footer {
    min-height: 200px;
    background-color: #000000;
    display: grid;
    place-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;
}
.tituloFooter {
    font-weight: bold;
    margin-bottom: 20px;
}
.policies{
    margin-bottom: 25px;
}
.nameDev{
    margin-bottom: 10px;
}
.socialFooter{
    margin-bottom: 15px;
}
.socialFooter img{
    margin-right: 10px;
}
.containerFooter { 
    max-width: 1200px ;
    min-width: 1200px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.linkFooter{
    color: #F2F2F2;
}

@media(max-width: 1236px){
    .containerFooter{
        min-width: 0px;
        grid-template-columns: 1fr;
        text-align: center;
    }
    .logoFooter{
        min-height: 100px;
        max-width: 	100px;
    }
    .containerFooter > div:last-child {
        display: grid;
        justify-content: center;
    }
    .socialFooter img{
        margin-right: 0px;
        padding: 10px;
    }
}

@media(max-width: 850px){

}
/* Log In and Register */
.form3 {
    min-height: 90vh;
    background: url(../assets/images/front-bg-small.f14153.jpg) 0/cover;
    display: grid;
    place-content: center;

}
.form3 > div:first-child {
    color: white;
    font-weight: bold;
    font-size: 44px;
    width: 450px;
    margin-bottom: 20px;
}
.divLogin{
    text-align: center;
    color: #0A73E5 !important;
    width: 550px ;
    padding: 40px;
    background-color: #F2F2F2;
}
.divLogin > p{
    padding-top: 10px;
    font-size: 15px;
}
.divLogin > form > p{
    padding-top: 10px;
    font-size: 15px;
}
.divLogin > div:first-child{
    margin-bottom: 20px;
}
.input__login{
    border: none;
    width: 435px;
    height: 29px;
    font-size: 16px;
    padding: 10px;
}

.submit__login{
    border: none;
    background-color: #0A73E5;
    color: white;
    width: 455px;
    height: 49px;
    font-size: 16px;
    padding: 10px;
}
.divLogin a{
    font-weight: 100;
    text-decoration: underline;
}
.titleLogout{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.titleLogout button{
    border: none;
    padding: 10px;
    background-color:#0A73E5;
    font-weight: 600;
    color: white;
}
@media(max-width: 850px){
    .divLogin{
        width: auto; 
    }
    .form3 > div:first-child {
        width: auto;
    }
    .input__login {
        width: 300px;
    }
    .submit__login {
        width: 300px;
    }
}
/* TOP */
.form2{
    display: grid;
    place-content: center;
    background: url("../assets/images/front-bg-small.f14153.jpg") no-repeat;
}
.containTop{
    margin-top: 80px;
    min-height: 100vh;
    max-width: 1200px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 4fr;   
}
.containPlayers{
    padding: 20px;
}
.containFilters{
    padding: 20px;
}
.containFilters select{
    border-color: #0A73E5;
    color: #0A73E5;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin-right: 2px;
    height: 39px;
    margin-top: 10px;
}
.topPlayers{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr 6fr 2fr;
    margin-top: 10px;
    background-color: #F2F2F2;
    border: #000000 1px solid;
    padding: 20px;
}
.topPlayers div img {
    width: 36px;
    height: 36px;
}
.imgTrophie{
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
}
.topPlayers > div:first-child{
    font-weight: bold;
}
.topPlayersClub{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr 6fr 2fr;
    margin-top: 10px;
    background-color: #0A73E5;
    border: #000000 1px solid;
    padding: 20px;
    font-weight: bold;
    color: white;
}
.topPlayersClub div img {
    width: 36px;
    height: 36px;
}
.topPlayersClub a{
    color: white;
}
@media(max-width: 850px){
    .containTop{
        grid-template-columns: 1fr;
    }
    .topPlayers{
        padding: 5px;
        grid-template-columns: 1fr 6fr 5fr 2fr;
    }
    .topPlayersClub{
        padding: 5px;
        grid-template-columns: 1fr 6fr 5fr 2fr;
    }
    .containPlayers{
        padding: 0;
    }
}

/* VS */
.winner {
    background-color: #A5FF9C;
}

.loser {
    background-color: #FF8D8D;
}

.tie {
    background-color: #F2F2F2;
}
.formVS {
    min-height: 90vh;
    background: url(../assets/images/front-bg-small.f14153.jpg) 0/cover;
    display: grid;
    place-content: center;

}
.formVS > div:first-child {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 44px;
    margin-bottom: 20px;
    width: 900px;
}
.searchBarVS{
    display: grid;
    grid-template-columns: 343px; 
    justify-content: center;
}
.divVS{
    text-align: center;
    color: white;
    display: grid;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
}
.detailsVS{
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.blueVS{
    background-color: #3191DF;
    border: #000000 solid 2px;
    margin-bottom: 40px;
   
}
.redVS{
    background-color: #E04D31;
    border: #000000 solid 2px;
    margin-bottom: 40px;
  
}
.infoTableVS{
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 120px;
    border: #000000 solid 2px;
}
.filaInfoVS {
    margin-top: 10px;
    background-color: #F2F2F2;
   
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px;
}


.columnaInfoVS {
    display: grid;
    grid-template-columns: 20% 60% 20%;
}
.columnaInfoVS img {
    height: 35px;
}
.columnaInfoVS div {
    font-size: 18px;
    font-weight: 600;
    align-content:  center;
}
.columnaInfoVS > div:nth-child(2) {
    text-align: left;
    
}
.columnaInfoVS > div:nth-child(3) {
    text-align: right;
    
}
.details__nameVS {
    font-size: 20px;
    font-weight: bold;
}
.iconImgVS {
    height: 77px;
}
.details__clubVS {
    display: flex;
    align-items: center;
    color: #3B4151;
    font-size: 15px;
    font-weight: 600;
    gap: 5px;
}

.imgTop3Brawl{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    padding: 40px;
}
.imgTop3Brawl img{
    height: 100px;
}
.vsBox{
    height: 250px;
}
.textVS{
    color: white;
    text-align: center;
    font-size: 24px;
}
.top3VS{
    margin-bottom: 10px;
}
.h1VS{
    color: #F2F2F2;
}
@media(max-width: 1236px){
    .imgTop3Brawl{
        padding: 0px;
    }
}

@media(max-width: 850px){

    .formVS > div:first-child {
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 44px;
        margin-bottom: 20px;
        width: auto;
    }
    .searchBarVS{
        display: grid;
        grid-template-columns: 343px; 
        justify-content: center;
    }
    .formVS > div:first-child {
        font-size: 30px;
        
    }
    .infoTableVS {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 10px;
    }
    .detailsVS{
        max-width: 1200px;
        margin: 0 auto;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr ;
    }
    .columnaInfoVS {
        display: grid;
        grid-template-columns: 15% 65% 20%;
    }
    .vsBox{
        display: grid;
       align-items: center;
    }
    .imgTop3Brawl img{
        height: 80px;
    }
}

/* Not Found */

.containerNotFound{
    display: grid;
    place-content: center;
    height: 70vh;
}

.loading {
    display: grid;
    place-items: center;
}
.loading img{
    height: 40px;
}